$background-grey: #3a3a3a;
$thin-border-grey: #9d9d9d;
$text-grey: #777777;
$primary: #ffee71;
$primary-hover: #eedd5f;
$secondary: #4a58a0;
$text-on-primary: #211f0f;
$panel-background: #525252;
$panel-edge: #8a8a8a;

$light-text-on-dark: #cdced3;
$light-text-on-panel: #f5f5f7;
$label-text-on-panel: #b6b6b6;

$btn-grey-on-panel: #b5b5b5;
$footer-background: #5a5a5a;
$error-text: #ff8383;

body {
  background-color: $background-grey;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  box-sizing: border-box;
}

a.header-link {
  text-decoration: none;
  color: $light-text-on-dark;
  :hover {
    color: $primary-hover;
  }
}

.body-width {
  width: 82em;
  margin: auto;
}

.structure {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  &__buffer {
    height: 15em;
    width: 100%;
  }

  &__footer {
    align-self: flex-end;
    margin-top: auto;
    width: 100%;
    min-height: 12em;
    background-color: $footer-background;
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1) inset,
      0 4px 8px rgba(0, 0, 0, 0.13) inset, 0 0 0 1px rgba(0, 0, 0, 0.02) inset;
    &__link {
      color: $light-text-on-panel;
      text-decoration: none;
      &:hover {
        color: $primary;
      }
    }
    &__inner {
      height: 100%;
      margin-top: 2em;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr 4em;
      color: $light-text-on-panel;
      ul {
        list-style: none;
        padding-left: 0;
        font-size: 0.75rem;
        & li {
          margin-bottom: 1em;
        }
        & .title {
          margin-bottom: 1.5em;
          font-size: 0.75rem;
          font-weight: 700;
          letter-spacing: 0.1rem;
        }
      }
      &__bottom {
        grid-column-start: 1;
        grid-column-end: 5;
        margin-top: 2em;
        border-top: 1px solid $thin-border-grey;
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 0.75rem;
      }
    }
  }
}

.intro-page {
  display: flex;
  flex-direction: column;

  &__section {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;
    }
    &__wrapper {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
    }
    &__inner {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        color: white;
        h1 {
          font-weight: 500;
          margin-bottom: 3em;
          line-height: 1.5em;
        }
        h3 {
          font-weight: 300;
          margin-bottom: 1em;
          line-height: 1.5em;
        }
      }
    }
    &__photo-credit {
      position: absolute;
      right: 1em;
      bottom: 1em;
      font-size: 0.8rem;
      a {
        text-decoration: none;
        color: white;
        &:hover {
          color: $primary;
        }
      }
    }
  }
  &__sub-section {
    width: 100%;
    height: 400px;
    position: relative;
    &__inner {
      display: flex;
      align-content: center;
      height: 100%;
      &__content {
        width: 80%;
        margin: auto;
        font-style: italic;
        color: white;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-items: center;
        h4 {
          font-weight: 300;
          margin-bottom: 2em;
          grid-column-start: 1;
          grid-column-end: 5;
          justify-self: flex-start;
        }
        img {
          width: 13em;
        }
      }
    }
  }
}

.text-on-background {
  color: $light-text-on-dark;
}

.label {
  font-size: 0.75rem;
}

.header {
  height: 5em;
  color: $light-text-on-dark;
  & h1 {
    font-weight: 300;
    font-size: 2.25rem;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    &__menu {
      display: flex;
    }
    &__item {
      margin-left: 3em;
    }
  }
}

.sub-header {
  margin: 1em auto auto auto;
  width: 82rem;
  font-weight: 300;
  font-size: 0.9rem;
  color: $light-text-on-dark;
}

.dashboard {
  display: grid;
  grid-template-columns: auto 1fr 2fr 2fr;
  grid-template-rows: auto auto;
  gap: 0.5em;
  width: 82em;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 2em;
}

.panel {
  color: $light-text-on-panel;
  background-color: $panel-background;
  padding: 0.5em;
  border-radius: 0.2em;
  box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.13),
    0 0 0 1px rgba(0, 0, 0, 0.02);
  & h4 {
    font-size: 1rem;
    margin-bottom: 0.8em;
    font-weight: 400;
    color: $light-text-on-panel;
  }
  &__panel-1 {
    grid-row-start: 1;
    grid-row-end: 3;
  }
  &__panel-2 {
    grid-row-start: 1;
    grid-row-end: 2;
  }
  &__panel-3 {
    grid-row-start: 2;
    grid-row-end: 3;
  }
  &__panel-4 {
    grid-row-start: 1;
    grid-row-end: 3;
  }
  &__panel-5 {
    grid-row-start: 1;
    grid-row-end: 3;
  }

  &__mounting-inner {
    height: 17em;
    width: 25em;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 0.5em;
  }
  &__mounting-tile {
    cursor: pointer;
  }
  &__body-colour-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__body-colour-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.2em;
    width: 3.2em;
    border: 1px solid $thin-border-grey;
    margin-bottom: 0.5em;
    font-weight: 700;
    color: white;
    & img {
      position: absolute;
    }
  }
  &__ip-rating-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__light-quality-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 2.5em);
  }
  &__ip-rating-box {
    // background-color: white;
    height: 3.2em;
    width: 3.2em;
    margin-bottom: 0.5em;

    & img {
      position: absolute;
      height: inherit;
      border: 1px solid $thin-border-grey;
    }
  }
  &__ip-rating-text {
    position: absolute;
    height: inherit;
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    color: $text-on-primary;
    font-size: 0.8rem;
    font-weight: 700;
  }
  &__icon-status-text {
    position: absolute;
    left: 0.2em;
    bottom: 0;
    font-size: 0.65rem;
  }
  &__colour-temp-box {
    position: relative;
    height: 3.2em;
    width: 3.2em;
    margin-right: 1em;
    img {
      border: 1px solid $thin-border-grey;
    }
  }
  &__cri-box {
    position: relative;
    height: 3.2em;
    width: 3.2em;
    margin-right: 1em;
    img {
      border: 1px solid $thin-border-grey;
    }
  }
  &__beam-angle-box {
    position: relative;
    height: 3.2em;
    width: 3.2em;
    margin-right: 1em;
    border: 1px solid $thin-border-grey;
  }
  &__dimensions-box {
    height: 6.4em;
    width: 6.4em;
    border: 1px solid $thin-border-grey;
    margin-right: 1em;
    background-color: $secondary;
  }
  &__dimension-tile {
    display: grid;
    grid-template-columns: auto 45px;
    gap: 0.5em;
  }
  &__tile {
    color: $light-text-on-panel;
    display: flex;
    align-items: flex-start;
    & img {
      object-fit: scale-down;
      height: 3.2em;
      margin-right: 0.25em;
    }
    & img.icons {
      object-fit: scale-down;
      height: 3.2em;
      margin-right: 0.25em;
      opacity: 0.8;
      transition: opacity 0.2s;
      &:hover {
        opacity: 1;
      }
      &.disabled {
        opacity: 0.2;
        &:hover {
          opacity: 0.4;
        }
      }
      &.selected {
        opacity: 1;
      }
    }
    &__bottom-bar {
      display: flex;
      align-items: center;
      width: 100%;
      height: 1.2em;
      & button {
        background-color: $btn-grey-on-panel;
        margin-right: 0.3em;
        height: 100%;
        border: 1px solid transparent;
        border-radius: 0.2em;
        cursor: pointer;
        transition: background-color 0.3s;
        color: $panel-background;
        font-weight: 400;
        font-size: 0.65rem;
        &:hover {
          background-color: $primary-hover;
        }
        &.outline {
          border: 1px solid $btn-grey-on-panel;
          background-color: transparent;
          color: $btn-grey-on-panel;
          font-weight: 400;
        }
      }
    }
  }
}

.message-box {
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    margin: 2em auto;
  }
  ul {
    margin-top: 1em;
    text-align: left;
  }
  li {
    margin-bottom: 1em;
  }
  h1 {
    margin-bottom: 2em;
    font-weight: 300;
    text-align: left;
  }
}

.search-bar {
  display: flex;
  justify-content: center;
}

.results-body {
  margin: 3em auto;
  width: 56em;
  .error-message {
    text-align: center;
    color: $light-text-on-dark;
  }
}

.product-tile {
  background-color: $panel-background;
  margin-bottom: 3em;
  padding: 1em;
  border-radius: 0.5em;
  display: grid;
  grid-template-columns: 9.25em 2fr 14em;
  grid-template-rows: 3.5em auto;
  column-gap: 2em;
  row-gap: 1em;
  border-radius: 0.2em;
  box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.13),
    0 0 0 1px rgba(0, 0, 0, 0.02);

  color: $light-text-on-panel;

  a.manu-link {
    font-size: 0.75rem;
    color: $light-text-on-panel;
    text-decoration: none;
    &:hover {
      color: $primary;
    }
  }
  .label-text {
    font-size: 0.7rem;
    color: $label-text-on-panel;
    font-weight: 700;
  }
  .product-info-text {
    font-size: 0.75rem;
  }
  .data {
    display: grid;
    grid-template-columns: 9em 1fr;
    grid-template-rows: repeat(6, auto);
    align-items: start;
    row-gap: 0.5em;
  }

  .manufacturer-data {
    display: grid;
    grid-template-columns: 4em 1fr;
    grid-template-rows: repeat(3, auto);
    align-items: start;
    row-gap: 0.5em;
    &__brand-img {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  & h2 {
    display: inline-block;
    font-size: 1.5rem;
    &.sub-heading {
      font-weight: 300;
    }
  }
  &__product-img {
    grid-row-start: 1;
    grid-row-end: 3;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__heading {
    grid-column-start: 2;
    grid-column-end: 4;
    a {
      color: inherit;
      text-decoration: inherit;
    }
    .hover-group-wrapper {
      display: flex;
    }
    .hover-group {
      display: flex;
      flex-direction: column;
      width: inherit;
      &__url {
        margin-top: 0.25em;
        font-size: 0.75rem;
        color: $label-text-on-panel;
      }
      &:hover {
        cursor: pointer;
        color: $primary;
      }
      &:hover .hover-group__url {
        cursor: pointer;
        color: $primary;
      }
    }
  }

  .manufacturer-section {
    border-left: solid 1pt $thin-border-grey;
    padding-left: 1em;
  }
}

.page-controls {
  display: flex;
  justify-content: flex-end;
  &__btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2em;
    width: 2em;
    margin-left: 0.4em;
    border: none;
    border-radius: 0.7em;
    font-size: 0.9rem;
    background-color: $btn-grey-on-panel;
    transition: background-color 0.2s;
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.13),
      0 0 0 1px rgba(0, 0, 0, 0.02);
    &:hover {
      background-color: white;
    }
  }

  .btn--disabled {
    cursor: inherit;
    background-color: $panel-background;
  }
  .btn--current {
    background-color: $primary;
  }
}

// --- ADD PRODUCT PAGE --- //

.form-body {
  width: 31em;
  margin: auto;
  margin-bottom: 10em;
  h2 {
    font-size: 2.4rem;
  }
  .form-width {
    width: 31em;
    margin: auto;
  }

  .align-top {
    align-self: start;
    margin-top: 2.2em;
  }

  &__grid {
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    row-gap: 2em;
    align-items: center;
  }

  &__option-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__sub-heading {
    margin-top: 4em;
    grid-column-start: 1;
    grid-column-end: 3;
    font-size: 1.2rem;
    padding-bottom: 1em;
    border-bottom: 1px solid $thin-border-grey;
  }

  &__explanation-text {
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom: 1em;
    font-style: italic;
    font-size: 0.8rem;
  }

  &__button {
    margin-top: 5em;
    display: flex;
    justify-content: center;
  }

  &__img-wrapper {
    height: 10em;
    width: 10em;
    padding: 0.5em;
    border: 1pt solid $thin-border-grey;
    border-radius: 0.4em;
    display: flex;
    img {
      object-fit: cover;
    }
  }

  &__manu-img-wrapper {
    height: 4.75em;
    width: 14em;
    padding: 0.5em;
    border: 1pt solid $thin-border-grey;
    border-radius: 0.4em;
    display: flex;
    img {
      object-fit: cover;
    }
  }

  &__error-msg {
    margin-top: 2em;
    color: $error-text;
    text-align: center;
  }
}

@media only screen and (max-width: 1350px) {
  .body-width {
    width: 56em;
  }

  .sub-header {
    width: 45rem;
  }

  .dashboard {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr 1fr 2fr;
    width: 45em;
  }
  .panel {
    &__panel-1 {
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: 5;
    }
    &__panel-2 {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 5;
      grid-column-end: 7;
    }
    &__panel-3 {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 5;
      grid-column-end: 7;
    }
    &__panel-4 {
      grid-row-start: 3;
      grid-row-end: 4;
      grid-column-start: 1;
      grid-column-end: 4;
    }
    &__panel-5 {
      grid-row-start: 3;
      grid-row-end: 4;
      grid-column-start: 4;
      grid-column-end: 7;
    }
  }
  .panel {
    &__mounting-inner {
      width: 28em;
    }
  }
  .intro-page {
    &__sub-section {
      &__inner {
        &__content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1em;
          h4 {
            grid-column-start: 1;
            grid-column-end: 3;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 920px) {
  .body-width {
    width: 45em;
  }

  .header {
    &__inner {
      width: 100%;
      padding-left: 2em;
      padding-right: 2em;
    }
  }

  .intro-page {
    &__section {
      &__inner {
        grid-template-columns: 1fr 1fr;
        &__content {
          h1 {
            margin-bottom: 1em;
            font-size: 1.5rem;
          }
          h3 {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .body-width {
    width: 28em;
  }

  .header {
    &__inner {
      width: 100%;
      padding-left: 2em;
      padding-right: 2em;
    }
  }

  .sub-header {
    width: 28rem;
  }

  .dashboard {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 9em 16em 12em;
    width: 28em;
  }
  .panel {
    &__panel-1 {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 3;
    }
    &__panel-2 {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: 2;
    }
    &__panel-3 {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 2;
      grid-column-end: 3;
    }
    &__panel-4 {
      grid-row-start: 3;
      grid-row-end: 4;
      grid-column-start: 1;
      grid-column-end: 3;
    }
    &__panel-5 {
      grid-row-start: 4;
      grid-row-end: 5;
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
  .panel {
    &__mounting-inner {
      width: 28em;
    }
  }

  .intro-page {
    &__section {
      height: 500px;
      background-color: red;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(0.5);
      }
      &__left-spacer {
        display: none;
      }
      &__inner {
        width: 80%;
        grid-template-columns: 1fr;
      }
    }
    &__sub-section {
      height: 800px;
      &__inner {
        &__content {
          display: grid;
          grid-template-columns: 1fr;
          gap: 1em;
          h4 {
            grid-column-start: 1;
            grid-column-end: 2;
          }
        }
      }
    }
  }
}
